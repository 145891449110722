import { post } from './http.js';
import module from './module.js';

const metaMap = {};
//需要改成同步 
const data = post(module.app + '/dict/v1.0/item/list', {}).then(res => {
    const newOptions = res.data.items.map((item) => {
        if (metaMap[item.dictCode] == null) {
            metaMap[item.dictCode] = {}
        }
        metaMap[item.dictCode][item.itemValue] = item.itemLabel;
    })
});

export function getMetaLabel(metaCode, itemCode) {
    if (metaMap[metaCode] != null) {
        return metaMap[metaCode][itemCode];
    } else {
        return itemCode;
    }
}