import React, { useCallback, useEffect, useState } from 'react'
import { Tree, TreeSelect } from 'antd';
import { post } from 'util/http';

const SyncTree = ({
    url,
    value,
    param,
    onChange,
    afterLoad = () => { },
    valueCode = "id",
    labelCode = "name",
    ...nextProps
}) => {

    const [treeData, setTreeData] = useState([]);

    const formatData = useCallback((data) => {
        var newData = {
            title: data[labelCode],
            value: data[valueCode],
            data: data, children: [],
            key: data[valueCode]
        };
        data.children.map((d) => {
            newData.children.push(formatData(d));
        })
        return newData;
    }, [])


    useEffect(() => {
        post(url, param || {})
            .then(res => {
                if (Array.isArray(res.data)) {
                    const newTreeData = res.data.map((item) => {
                        return formatData(item);
                    });
                    setTreeData(newTreeData);
                    afterLoad(newTreeData);
                } else {
                    const newTreeData = formatData(res.data);
                    setTreeData([newTreeData]);
                    afterLoad([newTreeData]);
                }

            })
    }, [])

    return (<Tree onChange={onChange} treeData={treeData} value={value}  {...nextProps} />)
}

export default SyncTree;