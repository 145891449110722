import './App.css';
import './pages/index';
import './pages/market';
import './pages/market-detail'
import './pages/solution';
import './pages/help';
import './pages/login';

import DynamicRoutes from 'component/DynamicRotes';

function App() {

  return (
    <DynamicRoutes />
  );
}

export default App;
