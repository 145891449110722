import React, { useState, useEffect } from 'react';

import { Button } from 'antd';

import { addRoute } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import Header from './head.js';

import './index.css'
import 'react-quill/dist/quill.snow.css';

import imgURL from '../asserts/images/logo.svg';

function Page() {

    const navigate = useNavigate();
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {

        post('/api/auth/auth/v1.0/check/login')
            .then(res => {
                console.log(res);
            })

        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };


    }, []);

    return (
        <div className="home">
            <div className={scrollY >= 78 ? "head scroll" : "head"} >
                <Header></Header>
            </div>


            <div className="h1">
                <div className="page-wrapper">
                    <div className='slogan'>
                        <div className='title'>
                            专注快速落地企业数字化<br />
                            云平台+DevOps+SaaS
                        </div>
                        <div className="intro">
                            以云平台为基石，结合DevOp研发模式，快速构建企业专属SaaS应用，助力企业快速数字化转型。支持云原生、混合云、私有化部署、更高效、更安全、更省钱！
                        </div>
                    </div>
                    <div>
                        <button className="primary">应用模板</button>
                        <button className='default'>免费试用</button>
                    </div>
                </div>

            </div>

            <div className='h2'>
                <div className="page-wrapper">
                    <div className="h2-t1 flex-row-center flex-justify-between">
                        <div className='h2-t1-item flex-column-center'>
                            <img src={require('../asserts/images/h2-t1-1.png')} />
                            <div className="font-18 mt-14">灵活的多云架构</div>
                            <div className='font-14 mt-14'>支持公有云、私有云、混合云架构</div>
                        </div>
                        <div className='h2-t1-item flex-column-center'>
                            <img src={require('../asserts/images/h2-t1-2.png')} />
                            <div className="font-18 mt-14">丰富的开放能力</div>
                            <div className='font-14 mt-14'>插件化设计，丰富组件Api灵活扩展</div>
                        </div>
                        <div className='h2-t1-item flex-column-center'>
                            <img src={require('../asserts/images/h2-t1-3.png')} />
                            <div className="font-18 mt-14">安全的生态网络</div>
                            <div className='font-14 mt-14'>支持私有化部署、物理隔离更加安全</div>
                        </div>
                        <div className='h2-t1-item flex-column-center'>
                            <img src={require('../asserts/images/h2-t1-4.png')} />
                            <div className="font-18 mt-14">便捷的搭建流程</div>
                            <div className='font-14 mt-14'>简单步骤，完成业务严谨的部署</div>
                        </div>
                    </div>

                    <div className='h2-t2 flex-row-center flex-justify-between'>
                        <img src={require('../asserts/images/h2.png')} />
                        <div className='flex-column-center'>
                            <div className='font-42'>一站式构建，实现完整方案</div>
                            <div className="h2-t2-content">样式太丑？没有后端开发支持？不会写前端页面？没有服务器？不会发布应用？不用担心，希禾云从原型、设计、开发、到部署一站式帮你搞定。</div>
                            <button className='learn-more'>了解更多</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='h3'>
                <div className="page-wrapper">
                    <div className='h2-t2 flex-row-center flex-justify-between'>

                        <div className='flex-column-center'>
                            <div className='font-42'>强大的后端数据能力</div>
                            <div className="h2-t2-content">强大的数据组件，支持云数据库、Excel、es等，登录、注册、注销、账号、鉴权等通用后端服务开箱即用</div>
                            {/* <div className='font-42'>强大的后端数据能力</div>
                            <div className="h2-t2-content">强大的数据组件，支持云数据库、Excel、es等，登录、注册、注销、账号、鉴权等通用后端服务开箱即用</div> */}
                            <button className='learn-more'>了解更多</button>
                        </div>
                        <img src={require('../asserts/images/h3.png')} />
                    </div>
                </div>
            </div>

            <div className='h4'>
                <div className="page-wrapper">
                    <div className='h2-t2 flex-row-center flex-justify-between'>
                        <img src={require('../asserts/images/h4.png')} />
                        <div className='flex-column-center'>
                            <div className='font-42'>提供丰富品类SaaS模版</div>
                            <div className="h2-t2-content">解决多行业、多场景、多角色不同问题。大屏监控、仪表盘、后台管理功能、官网等业务形态一键生成。更多业务形态或样式敬请期待，也可直接联系我们</div>
                            <button className='learn-more'>了解更多</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className='h5'>
                <div className="page-wrapper">
                    <div className="title">现成应用模板，点击直接启用</div>
                    <div className='flex-row-center flex-justify-between'>
                        <div>
                            <img src={require('../asserts/images/h5-1.png')} />
                            <div className="content">App Widget 组件ui .fig素材下载</div>
                        </div>
                        <div>
                            <img src={require('../asserts/images/h5-2.png')} />
                            <div className="content">App Widget 组件ui .fig素材下载</div>
                        </div>
                        <div>
                            <img src={require('../asserts/images/h5-3.png')} />
                            <div className="content">App Widget 组件ui .fig素材下载</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='h6'>
                <div className="page-wrapper text-center">
                    <div className='title'>
                        您可以直接使用模版和服务接口，也可使用我们的编辑器，快速搭建一个属于你的专属页面
                    </div>
                    <button>进入编辑器</button>
                </div>
            </div>


            <div className='footer'>
                <div className="page-wrapper">
                    <div className='content flex-row-top flex-justify-between'>
                        <div className='flex-column-center'>
                            <div className="title">关于希禾云</div>
                            <div className='flex-column-center '>
                                <a>管理团队</a>
                                <a>成长历程</a>
                                <a>企业文化</a>
                                <a>公司新闻</a>
                            </div>
                        </div>
                        <div className='flex-column-center'>
                            <div className="title">业务介绍</div>
                            <div className='flex-column-center '>
                                <a>混合云管理平台</a>
                                <a>便民365平台</a>
                                <a>低代码管理平台</a>
                            </div>
                        </div>
                        <div className='flex-column-center'>
                            <div className="title">网站信息</div>
                            <div className='flex-column-center'>
                                <a>法律声明</a>
                                <a>隐私政策</a>
                                <a>监督举报</a>
                            </div>
                        </div>
                        <div className='flex-column-center'>
                            <div className="title">联系我们</div>
                            <div className='flex-column-center content'>
                                <a>18911920791</a>
                            </div>
                        </div>
                        <div>
                            二维码
                        </div>
                    </div>
                    <div className="beian color-gray">
                        <a href="https://beian.miit.gov.cn/" target="_blank" className='mr-54'>京ICP备2021025957号</a>
                        <div>©Copyright 2022 北京希禾云科技有限公司</div>
                    </div>

                </div>


            </div>


        </div >


    );
}
addRoute("*", Page);
export default Page;
