import React, { useState, useEffect } from 'react';

import { addRoute } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row, Button, message, Modal, Form, Input } from 'antd';
import SearchSelect from 'component/SearchSelect';
import Header from './head.js';
import { post } from 'util/http';

import { EyeOutlined, EditOutlined, AimOutlined } from '@ant-design/icons';

function Page() {

    const navigate = useNavigate();

    const [pageForm] = Form.useForm();

    const [param, setParam] = useState({});
    const [pageData, setPageData] = useState({
        size: 1,
        records: []
    });
    const [visible, setVisible] = useState(false);
    // const 
    const loadData = () => {
        post("/api/cloud/public/template/search", param).then(res => {
            setPageData(res.data);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="market ">
            <div className="head scroll" >
                <Header></Header>
            </div>

            <div className="market-template ">
                <div className="page-wrapper">

                    <Row gutter={[16, 16]}>
                        {pageData.records.map((item) => (
                            <Col span={6} className="item" key={item.id}>
                                <div className="item-img">
                                    <img src={item.picture} />
                                    <div className="bg flex-column-center flex-justify-center">
                                        <Button
                                            onClick={() => {
                                                window.open("/market/detail?code=" + item.code);
                                                // navigate("/market/detail?code=" + item.code);
                                            }}
                                        ><AimOutlined />详情</Button>
                                        <Button className="mt-14" onClick={() => {
                                            pageForm.setFieldsValue({
                                                templateCode: item.code
                                            })
                                            setVisible(true)
                                        }}><EditOutlined /> 创建应用</Button>
                                    </div>
                                </div>
                                <div className="title">
                                    <div className="count">
                                        <EyeOutlined /> 100
                                    </div>
                                    <div className='main'>{item.name}</div>
                                    <div className='sub'>{item.remark}</div>
                                </div>
                            </Col>
                        ))}


                    </Row>
                </div>
            </div>


            <Modal title="创建应用" visible={visible} onOk={() => {
                pageForm.validateFields().then((data) => {
                    post('/api/cloud/my/application/create', data)
                        .then(res => {
                            message.info("创建成功");
                            setVisible(false)
                        })
                })

            }} onCancel={() => {
                setVisible(false)
            }}>
                <Form form={pageForm} layout="horizontal" hideRequiredMark
                    labelCol={{
                        style: { width: 80 }
                    }}
                    initialValues={{
                        attrs: []
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="templateCode"
                                style={{ display: "none" }}
                            >
                                <Input type={"hidden"} />
                            </Form.Item>
                            <Form.Item
                                name="clusterCode"
                                label="所属集群"
                                rules={[{ required: true, message: '请选择集群' }]}
                            >
                                <SearchSelect url={"/api/cloud/cluster/v1.0/search"} labelCode="name" valueCode="code" />
                            </Form.Item>

                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="appId"
                                label="appId"
                                rules={[{ required: true, message: '请输入appId' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入appId"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="名称"
                                rules={[{ required: true, message: '请输入名称' }]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="请输入名称"

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div >

    );
}
addRoute("/market", Page);
export default Page;
