import React, { useEffect, useState } from 'react'
import {
    Button,
    Col,
    Modal,
    Form,
    Input,
    message,
    Row,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchSelect from 'component/SearchSelect'
import { getMetaLabel } from 'util/meta';

const Page = ({
    param,
    setParam,
    reload
}) => {

    const [pageForm] = Form.useForm();

    return (<Modal title="创建应用" visible={param.visible} onOk={() => {
        pageForm.validateFields().then((data) => {
            data.templateCode = param.templateCode;
            post('/api/cloud/my/application/create', data)
                .then(res => {
                    message.info("创建成功");
                    setParam({ ...param, visible: false });
                    window.open(getMetaLabel("APP_DOMAIN", 'console') + "/my/application")
                })
        })

    }} onCancel={() => {
        setParam({ ...param, visible: false })
    }}>
        <Form form={pageForm} layout="horizontal" hideRequiredMark
            labelCol={{
                style: { width: 80 }
            }}
            initialValues={{
                attrs: []
            }}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="clusterCode"
                        label="所属集群"
                        rules={[{ required: true, message: '请选择集群' }]}
                    >
                        <SearchSelect url={"/api/cloud/cluster/v1.0/search"} labelCode="name" valueCode="code" />
                    </Form.Item>

                </Col>
                <Col span={24}>
                    <Form.Item
                        name="appId"
                        label="appId"
                        rules={[{ required: true, message: '请输入appId' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入appId"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={[{ required: true, message: '请输入名称' }]}
                    >
                        <Input
                            style={{ width: '100%' }}
                            placeholder="请输入名称"

                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>)
}

export default Page;