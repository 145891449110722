import React, { useState, useEffect } from 'react';

import { Button, Form, Input, message, Popconfirm, Space, Menu, Layout, Breadcrumb, Row, Col } from 'antd';

import { addRoute } from 'component/DynamicRotes';
import SyncTree from 'component/SyncTree.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import { RollbackOutlined, DownOutlined } from '@ant-design/icons';

import MarketForm from './market-form'

import Header from './head.js';
import imgURL from '../asserts/images/logo.svg';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {

    const navigate = useNavigate();
    const [template, setTemplate] = useState({});
    const [formParam, setFormParam] = useState({ visible: false });

    let query = useQuery();


    useEffect(() => {
        post("/api/cloud/public/template/get-by-code", {
            code: query.get("code")
        }).then(res => {
            setTemplate(res.data);
        })
    }, [])

    return (
        <div className="market">
            <div className="head scroll" >
                <Header></Header>
            </div>

            <div className="market-detail">
                <div className="page-wrapper">
                    <div>
                        <div style={{ float: 'right' }}>

                        </div>
                        <Breadcrumb>
                            <Breadcrumb.Item href="#" onClick={() => {
                                navigate("/market")
                            }}>
                                / 应用市场
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {template.name}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row >
                        <Col flex="1" className='doc-content ql-editor'>
                            <div dangerouslySetInnerHTML={{
                                __html: template.content
                            }}
                            />
                        </Col>
                        <Col flex="300px" className='template-info'>
                            <img src={template.picture} style={{ width: 300 }} />
                            <p style={{ fontWeight: 'bold', paddingTop: 30 }}>{template.name}</p>
                            <p>说明：{template.remark}</p>
                            <p>作者：{template.creatorName}</p>
                            <p>发布时间：{template.creatorName}</p>
                            <p>当前版本：{template.creatorName}</p>
                            <div className='flex-row-center flex-justify-center pt-30'>
                                <Button type="primary" onClick={() => {
                                    setFormParam({
                                        ...formParam,
                                        visible: true,
                                        templateCode: template.code
                                    })
                                }}> 创建应用</Button>
                            </div>

                        </Col>

                    </Row>
                </div>
            </div>

            <MarketForm param={formParam} setParam={setFormParam} />
        </div >


    );
}
addRoute("/market/detail", Page);
export default Page;
