
import React, { useState, useEffect } from 'react';

import { Button, Dropdown, Space, Avatar, Menu } from 'antd';
import { post } from 'util/http';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { getMetaLabel } from 'util/meta';

function Page() {

    const navigate = useNavigate();
    const [hasLogin, setHasLogin] = useState(null);
    const loginUser = {
        name: "系统管理员"
    };

    const location = useLocation();

    console.log(location.pathname)

    useEffect(() => {
        post('/api/auth/auth/v1.0/check/login')
            .then(res => {
                setHasLogin(res.data);
            })
    }, []);

    const menu = (
        <Menu
            onClick={(item) => {
                if (item.key == 'logout') {
                    post('/api/auth/auth/v1.0/logout', {})
                        .then(res => {
                            window.location.href = "/login";
                        })
                }
            }}
            items={[
                {
                    label: '退出登录',
                    key: 'logout',
                }
            ]}
        />
    );

    return (<div className="page-wrapper">
        <div className="flex-row-top flex-justify-between">
            <div className="logo ">
                <img src={require('../asserts/images/logo.png')} style={{ height: 36 }} />
                {/* <img src={imgURL} /> */}
            </div>
            <div className="nav">
                <a href="#" onClick={() => { navigate("/") }} className={location.pathname == '/' ? 'selected' : ''}>首页</a>
                <a href="#" onClick={() => { navigate("/market") }} className={location.pathname == '/market' ? 'selected' : ''} >应用市场</a>
                <a href="#" onClick={() => { navigate("/solution") }} className={location.pathname == '/solution' ? 'selected' : ''}>解决方案</a>
                <a href="#" onClick={() => { navigate("/help") }} className={location.pathname == '/help' ? 'selected' : ''}>帮助文档</a>
            </div>
            <div className="btn-logins">
                {hasLogin && <div>
                    <a className="mr-20 color-black" href="#" onClick={() => {
                        window.location.href = getMetaLabel("APP_DOMAIN", 'console') + "/my/application"
                    }} >控制台</a>
                    <Dropdown overlay={menu} >
                        <Space style={{ cursor: "pointer" }}>
                            <Avatar icon={<UserOutlined />} />
                            {/* <span className="color-black">{loginUser.name}</span> */}
                            {/* <DownOutlined /> */}
                        </Space>
                    </Dropdown></div>}
                {hasLogin != null && !hasLogin && (<Space>
                    <Button className='btn-login ' onClick={() => { navigate("/login") }}>登录</Button>
                    <Button className='btn-login btn-select'>注册</Button>
                </Space>)}


            </div>
        </div>
    </div>);
}
export default Page;