import axios from "axios";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
const parseResponse = (res, success, fail) => {
    switch (res.status) {
        case 0:
            success(res);
            break;
        case 9999:
            message.error(res.msg);
            window.location.href = "/login";
            break;
        default:
            message.error(res.msg + "[" + res.data + "]");
            fail && fail(res);
    }
}
export function post(url, param = {}, config = {}) {
    return new Promise((success, fail, reject) => {
        axios.post(url, param).then((res) => {
            if (!config.ignoreParse) {
                parseResponse(res.data, success, fail);
            } else {
                success(res);
            }
        }, (err) => {
            message.error(err.message + "  [" + err.config.url + "]");
            reject && reject(err);
        })
    })
}

export function get(url, param = {}, config = {}) {
    return new Promise((success, fail, reject) => {
        axios.get(url, param).then((res) => {
            if (!config.ignoreParse) {
                parseResponse(res.data, success, fail);
            } else {
                success(res);
            }

        }, (err) => {
            reject && reject(err);
        })
    })
}