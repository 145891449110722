import React, { useState, useEffect } from 'react';

import { Button, Form, Input, message, Popconfirm, Space, Menu, Layout, Tree, Row, Col } from 'antd';

import { addRoute } from 'component/DynamicRotes';
import SyncTree from 'component/SyncTree.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';



import Header from './head.js';
import imgURL from '../asserts/images/logo.svg';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Page() {

    const navigate = useNavigate();
    const [article, setArticle] = useState({});
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    let query = useQuery();


    const getArtile = (code) => {
        post("/api/app/public/meta/data/get-by-code", {
            code: code,
            metaCode: "cmsArticle"
        }).then(res => {
            setArticle(res.data);
        })
    }
    useEffect(() => {
    }, [])

    return (
        <div className="doc">
            <div className="head scroll" >
                <Header></Header>
            </div>

            <div className="doc-center ">
                <div className="page-wrapper">
                    <Row >
                        <Col flex="266px" className='doc-tree'>
                            <SyncTree url={"/api/app/public/meta/data/tree"}
                                expandedKeys={expandedKeys}
                                autoExpandParent={true}
                                selectedKeys={selectedKeys}
                                param={{
                                    metaCode: 'cmsArticle',
                                    showRoot: false,
                                    nameAttr: "title",
                                    params: [{
                                        attrCode: 'channelCode',
                                        operator: "=",
                                        value: "help"
                                    }, {
                                        attrCode: 'status',
                                        operator: "=",
                                        value: "online"
                                    }]
                                }}
                                valueCode="code"
                                onSelect={(v, e) => {
                                    setSelectedKeys(v)
                                    getArtile(e.node.data.code)
                                    window.history.pushState({}, null, "/help?code=" + e.node.data.code);
                                }}
                                afterLoad={(data) => {
                                    if (query.get("code") != null) {
                                        setExpandedKeys([query.get("code")])
                                        setSelectedKeys([query.get("code")])
                                        getArtile(query.get("code"))
                                    } else {
                                        setExpandedKeys([data[0].key])
                                        setSelectedKeys([data[0].key])
                                        getArtile(data[0].key)
                                    }
                                }}
                                onExpand={(expandedKeys) => {
                                    setExpandedKeys(expandedKeys)
                                }}
                            />
                        </Col>
                        <Col flex="1" className='doc-content ql-editor'>
                            <div dangerouslySetInnerHTML={{
                                __html: article.content
                            }}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </div >


    );
}
addRoute("/help", Page);
export default Page;
