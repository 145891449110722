import React, { useEffect, useState } from 'react'
import { Select } from 'antd';
import { post } from 'util/http';

const SearchSelect = ({
    url,
    value,
    param,
    labelCode = "name",
    valueCode = "code",
    onChange,
    labelInValue = false,
    mode,
    ...nextProps
}) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        post(url, param || {})
            .then(res => {
                const newOptions = res.data.records.map((item) => {
                    return {
                        label: item[labelCode],
                        value: item[valueCode],
                        data: item
                    }
                })
                setOptions(newOptions);
            })
    }, [param])

    return (<Select value={value} onChange={onChange} mode={mode} labelInValue={labelInValue} {...nextProps}
        options={options} >
    </Select >)
}

export default SearchSelect;