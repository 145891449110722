import React, { useState, useEffect } from 'react';

import { Button, Input, Space, Drawer, Form, Col, Row, Select, message, Switch } from 'antd';

import { addRoute } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from 'util/http';
import './login.css'
import Header from './head.js';

import imgURL from '../asserts/images/logo.svg';

function Page() {

    const navigate = useNavigate();
    const [loginForm] = Form.useForm();

    return (
        <div className="login">
            <div className="head scroll" >
                <Header></Header>
            </div>

            <div>
                <div className="login-poster"></div>
                <div className="login-area">
                    <Form form={loginForm} layout="horizontal" hideRequiredMark
                        style={{
                            marginTop: 200,
                            paddingLeft: 70
                        }}
                        labelCol={{
                            style: {
                                width: 80,
                            }
                        }}
                        initialValues={{
                            items: []
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={24} style={{ marginBottom: 15 }}>
                                <Form.Item
                                    name="accountCode"
                                    rules={[{ required: true, message: '请输入账号' }]}
                                >
                                    <Input
                                        style={{ width: '100%' }} size="large"
                                        placeholder="请输入账号"
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={24} style={{ marginBottom: 15 }}>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请选输入密码' }]}
                                >
                                    <Input
                                        type="password" size="large"
                                        style={{ width: '100%' }}
                                        placeholder="请选输入密码"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Button block type='primary' size="large" onClick={() => {
                                    loginForm.validateFields().then((values) => {
                                        post("/api/auth/auth/v1.0/login", values)
                                            .then(res => {
                                                message.info("登录成功");
                                                navigate("/");
                                            })
                                    })
                                }} >提交</Button>
                            </Col>
                        </Row>
                    </Form>

                </div>

            </div>
        </div >

    );
}
addRoute("/login", Page);
export default Page;
